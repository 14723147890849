import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css"; // Import cube animation styles
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import { Container } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import slide1 from "./images/slide-1.webp";
import slide2 from "./images/slide-2.webp";
import slide3 from "./images/slide-3.webp";
import slide4 from "./images/slide-4.webp";
import slide5 from "./images/slide-5.webp";

import slideMobile1 from "./images/slide-1-mobile.jpg";
import slideMobile2 from "./images/slide-2-mobile.jpg";
import slideMobile3 from "./images/slide-3-mobile.jpg";
import { useMediaQuery } from "react-responsive";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Banner = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <AutoplaySlider
        animation="cubeAnimation"
        play={true}
        cancelOnInteraction={true}
        interval={5000}
      >
        <div data-src={isMobile ? slideMobile1 : slide1}></div>
        <div data-src={isMobile ? slideMobile2 : slide2}></div>
        <div data-src={isMobile ? slide3 : slide3}></div>
        <div data-src={isMobile ? slide4 : slide4}></div>
        <div data-src={isMobile ? slide5 : slide5}></div>
      </AutoplaySlider>
    </>
  );
};

export default Banner;
