import React from "react";

const SvgLogo = ({ width, height, className }) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 800 800"
        style={{
          enableBackground: "new 0 0 800 800",
        }}
        xmlSpace="preserve"
        width={width}
        height={height}
        className={className}
      >
        <g>
          <path
            class="st0"
            d="M170.04,49.05l41.62,37.92l-71.21,95.25c0,0,12.95,68.47,59.19,110.09l-46.24,38.8
		c0,0-76.76-90.63-85.08-147.04C68.32,184.07,111.78,102.73,170.04,49.05z"
          />
          <path
            class="st0"
            d="M238.48,318.16c0,0,2.77-240.44,11.1-249.69h49.01v190.51l65.66,0.92l-17.57,58.26H238.48z"
          />
          <path
            class="st0"
            d="M343.9,175.28c0,0,81.84-105.43,111.9-126.23l31.44,43.47l-56.41,61.5l56.41,4.16l-2.77,55.49h-56.41
		l56.41,81.84l-49.01,35.6C435.46,331.11,355,217.36,343.9,175.28z"
          />
          <path
            class="st0"
            d="M595.91,43.5c-36.07,29.59-86.47,132.24-86.47,132.24c20.35,50.86,108.2,142.42,108.2,142.42
		c41.62-45.78,83.69-142.42,83.69-142.42C654.63,89.28,595.91,43.5,595.91,43.5z M612.09,230.31l-36.07-51.33l25.43-51.33
		l36.07,53.18L612.09,230.31z"
          />
          <polygon
            class="st0"
            points="576.03,351.92 585.27,594.21 632.44,594.21 632.44,496.18 686.08,594.21 735.55,594.21 672.2,455.96 
		716.13,374.11 676.36,343.59 630.59,418.04 625.96,348.68 	"
          />
          <path
            class="st0"
            d="M492.33,473.99l40.69-73.96c-68.9-47.6-147.04-54.13-147.04-54.13l8.32,248.31h49.94v-62.42l11.1-10.64
		l46.7,73.06h55.95L492.33,473.99z M444.07,462.55l1.56-45.43l23,11.75L444.07,462.55z"
          />
          <path
            class="st0"
            d="M74.79,339.9c0,0-10.17,174.32-3.7,254.32h49.48v-64.74l84.62-124.85C205.19,404.63,140.76,355.15,74.79,339.9
		z M118.26,457.34l-3.24-45.78l27.74,12.48L118.26,457.34z"
          />
          <path
            class="st0"
            d="M298.13,352.84h-50.86l-51.33,241.37h50.86l8.32-43.47h43l9.25,43.47h53.18L298.13,352.84z M262.06,501.27
		c6.94-49.48,12.25-43.93,12.25-43.93c4.39-5.09,12.25,43.93,12.25,43.93H262.06z"
          />
          <g>
            <path
              class="st0"
              d="M64.43,643.73h31.13v6.82H64.43V643.73z M76.46,647.28h7.08v39.73h-7.08V647.28z"
            />
            <path
              class="st0"
              d="M121.44,643.73h7.11v43.28h-7.11V643.73z M125.21,662.22h24.61v6.82h-24.61V662.22z M145.57,643.73h7.11
			v43.28h-7.11V643.73z"
            />
            <path
              class="st0"
              d="M180.87,643.73h7.11v43.28h-7.11V643.73z M183.82,643.73h25.45v6.82h-25.45V643.73z M183.82,662.13h18.55
			v6.79h-18.55V662.13z M183.82,680.19h25.45v6.82h-25.45V680.19z"
            />
            <path
              class="st0"
              d="M268.82,643.03h7.11v43.28h-7.11V643.03z M272.25,662.71h13.84c1.15,0,2.16-0.26,3.02-0.79
			c0.86-0.53,1.54-1.28,2.02-2.25c0.49-0.97,0.73-2.09,0.73-3.36c0-1.29-0.24-2.42-0.71-3.39c-0.48-0.97-1.15-1.73-2.02-2.26
			c-0.87-0.54-1.89-0.8-3.04-0.8h-13.84v-6.82h13.63c2.64,0,4.96,0.55,6.96,1.65c2,1.1,3.56,2.65,4.67,4.66
			c1.11,2,1.67,4.33,1.67,6.96c0,2.64-0.56,4.96-1.67,6.95c-1.11,1.99-2.67,3.54-4.67,4.63c-2,1.09-4.33,1.64-6.96,1.64h-13.63
			V662.71z"
            />
            <path
              class="st0"
              d="M325.48,643.03h7.11v43.28h-7.11V643.03z M329.26,661.52h24.61v6.82h-24.61V661.52z M349.62,643.03h7.11
			v43.28h-7.11V643.03z"
            />
            <path
              class="st0"
              d="M395.04,643.03h5.18l16.25,43.28h-7.65l-11.19-32.23l-11.19,32.23h-7.65L395.04,643.03z M386.44,671.28h22.77
			v6.81h-22.77V671.28z"
            />
            <path
              class="st0"
              d="M437.56,643h7.11v43.31h-7.11V643z M440.51,661.63h14.91c0.99,0,1.87-0.24,2.63-0.73
			c0.76-0.49,1.35-1.18,1.77-2.07c0.42-0.89,0.63-1.93,0.66-3.13c0-1.17-0.21-2.2-0.63-3.1c-0.42-0.89-1.01-1.58-1.77-2.07
			c-0.76-0.49-1.65-0.73-2.66-0.73h-14.91V643h15.12c2.44,0,4.58,0.53,6.43,1.58c1.85,1.05,3.28,2.54,4.3,4.47
			c1.02,1.92,1.53,4.15,1.53,6.67c0,2.54-0.51,4.77-1.53,6.7s-2.46,3.41-4.32,4.46s-3.99,1.58-6.41,1.58h-15.12V661.63z
			 M450.95,667.11l7.38-1.37l11.37,20.57h-8.54L450.95,667.11z"
            />
            <path
              class="st0"
              d="M507.73,643.03h5.18l16.25,43.28h-7.65l-11.19-32.23l-11.19,32.23h-7.65L507.73,643.03z M499.13,671.28h22.77
			v6.81h-22.77V671.28z"
            />
            <path
              class="st0"
              d="M556.98,684.77c-2.34-1.32-4.16-3.18-5.45-5.59c-1.29-2.41-1.93-5.21-1.93-8.38v-12.26
			c0-3.17,0.64-5.97,1.93-8.38c1.29-2.41,3.11-4.28,5.45-5.6c2.34-1.32,5.05-1.98,8.13-1.98s5.78,0.66,8.12,1.98
			c2.34,1.32,4.16,3.19,5.45,5.6c1.29,2.41,1.93,5.2,1.93,8.38v12.26c0,3.17-0.64,5.97-1.93,8.38c-1.29,2.41-3.1,4.27-5.45,5.59
			c-2.34,1.32-5.05,1.98-8.12,1.98S559.32,686.1,556.98,684.77z M569.4,678.63c1.24-0.71,2.2-1.73,2.87-3.05
			c0.68-1.32,1.01-2.84,1.01-4.57v-12.68c0-1.73-0.34-3.25-1.01-4.57c-0.67-1.32-1.63-2.34-2.87-3.05c-1.24-0.71-2.67-1.07-4.3-1.07
			c-1.63,0-3.06,0.36-4.3,1.07c-1.24,0.71-2.2,1.73-2.87,3.05c-0.68,1.32-1.01,2.84-1.01,4.57v12.68c0,1.73,0.34,3.25,1.01,4.57
			c0.67,1.32,1.63,2.34,2.87,3.05c1.24,0.71,2.67,1.07,4.3,1.07C566.73,679.7,568.16,679.34,569.4,678.63z"
            />
            <path
              class="st0"
              d="M606.51,643.03h7.74l18.63,33.3l-0.71,0.77v-34.08h6.9v43.25h-7.8l-18.57-32.8l0.71-0.77v33.57h-6.9V643.03z"
            />
            <path class="st0" d="M675.6,686.31h-7.26v-43.28h7.26V686.31z" />
            <path
              class="st0"
              d="M709.8,684.8c-2.31-1.3-4.09-3.16-5.33-5.58c-1.24-2.42-1.86-5.29-1.86-8.6v-11.87c0-3.31,0.62-6.18,1.86-8.6
			c1.24-2.42,3.02-4.29,5.33-5.59c2.31-1.31,5.04-1.96,8.17-1.96c2.6,0,4.95,0.56,7.07,1.67c2.11,1.11,3.87,2.71,5.27,4.81
			c1.4,2.09,2.36,4.57,2.87,7.43h-7.32c-0.36-1.39-0.92-2.61-1.7-3.65c-0.77-1.04-1.69-1.84-2.75-2.39
			c-1.06-0.56-2.21-0.83-3.44-0.83c-1.65,0-3.07,0.37-4.27,1.12c-1.2,0.74-2.13,1.8-2.78,3.17c-0.65,1.37-0.98,2.99-0.98,4.85v11.87
			c0,1.87,0.33,3.48,0.98,4.85c0.65,1.37,1.58,2.42,2.78,3.16c1.2,0.73,2.62,1.1,4.27,1.1c1.25,0,2.41-0.27,3.47-0.8
			c1.06-0.54,1.98-1.32,2.75-2.35c0.77-1.03,1.33-2.27,1.67-3.72h7.32c-0.54,2.86-1.5,5.33-2.9,7.43c-1.4,2.09-3.16,3.7-5.27,4.81
			c-2.11,1.11-4.46,1.67-7.04,1.67C714.84,686.75,712.12,686.1,709.8,684.8z"
            />
            <path
              class="st0"
              d="M162.89,715.11h5.36l8.57,31.4l6.61-31.4h7.35l-10.27,43.28h-6.94l-7.83-30.24l-8.19,30.24h-6.93
			l-10.27-43.28h7.35l6.61,31.4L162.89,715.11z"
            />
            <path
              class="st0"
              d="M221.44,715.11h5.18l16.25,43.28h-7.65l-11.19-32.23l-11.19,32.23h-7.65L221.44,715.11z M212.83,743.35h22.77
			v6.81h-22.77V743.35z"
            />
            <path
              class="st0"
              d="M259.22,715.11h31.13v6.82h-31.13V715.11z M271.24,718.65h7.08v39.73h-7.08V718.65z"
            />
            <path
              class="st0"
              d="M312.76,715.11h7.11v43.28h-7.11V715.11z M315.7,715.11h25.45v6.82H315.7V715.11z M315.7,733.5h22.17v6.79
			H315.7V733.5z M315.7,751.57h25.45v6.82H315.7V751.57z"
            />
            <path
              class="st0"
              d="M366.49,715.08h7.11v43.31h-7.11V715.08z M369.44,733.71h14.91c0.99,0,1.87-0.24,2.63-0.73
			c0.76-0.49,1.35-1.18,1.77-2.07s0.63-1.93,0.66-3.13c0-1.17-0.21-2.2-0.63-3.1s-1.01-1.58-1.77-2.07
			c-0.76-0.49-1.65-0.73-2.66-0.73h-14.91v-6.82h15.12c2.44,0,4.58,0.53,6.43,1.58c1.85,1.05,3.28,2.54,4.3,4.47
			c1.02,1.92,1.53,4.15,1.53,6.67c0,2.54-0.51,4.77-1.53,6.7c-1.02,1.92-2.46,3.41-4.32,4.46c-1.86,1.05-3.99,1.58-6.41,1.58h-15.12
			V733.71z M379.88,739.19l7.38-1.37l11.37,20.57h-8.54L379.88,739.19z"
            />
            <path
              class="st0"
              d="M460.33,715.11h7.11v43.28h-7.11V715.11z M463.75,734.78h13.84c1.15,0,2.16-0.26,3.02-0.79
			c0.86-0.53,1.54-1.28,2.02-2.25c0.49-0.97,0.73-2.09,0.73-3.36c0-1.29-0.24-2.42-0.71-3.39c-0.48-0.97-1.15-1.73-2.02-2.26
			c-0.87-0.54-1.89-0.8-3.04-0.8h-13.84v-6.82h13.63c2.64,0,4.96,0.55,6.96,1.65c2.01,1.1,3.56,2.65,4.67,4.66
			c1.11,2,1.67,4.33,1.67,6.96c0,2.64-0.56,4.96-1.67,6.95c-1.11,1.99-2.67,3.54-4.67,4.63c-2,1.09-4.32,1.64-6.96,1.64h-13.63
			V734.78z"
            />
            <path
              class="st0"
              d="M523.34,715.11h5.18l16.25,43.28h-7.65l-11.19-32.23l-11.19,32.23h-7.65L523.34,715.11z M514.74,743.35h22.77
			v6.81h-22.77V743.35z"
            />
            <path
              class="st0"
              d="M565.86,715.08h7.11v43.31h-7.11V715.08z M568.81,733.71h14.91c0.99,0,1.87-0.24,2.63-0.73
			c0.76-0.49,1.35-1.18,1.77-2.07c0.42-0.89,0.63-1.93,0.65-3.13c0-1.17-0.21-2.2-0.63-3.1c-0.42-0.89-1.01-1.58-1.77-2.07
			c-0.76-0.49-1.65-0.73-2.66-0.73h-14.91v-6.82h15.12c2.44,0,4.58,0.53,6.43,1.58c1.84,1.05,3.28,2.54,4.3,4.47
			c1.02,1.92,1.53,4.15,1.53,6.67c0,2.54-0.51,4.77-1.53,6.7c-1.02,1.92-2.46,3.41-4.32,4.46s-3.99,1.58-6.41,1.58h-15.12V733.71z
			 M579.25,739.19l7.38-1.37L598,758.38h-8.54L579.25,739.19z"
            />
            <path
              class="st0"
              d="M622.19,715.11h7.11v43.28h-7.11V715.11z M627.04,740.44l18.21-25.33h8.87l-27.02,35.18L627.04,740.44z
			 M634.24,735.88l5.89-4.44l15.8,26.94h-8.54L634.24,735.88z"
            />
          </g>
        </g>
        <g>
          <g class="st1">
            <path
              class="st2"
              d="M57.05,667.85h17.19v3.76H57.05V667.85z M63.69,669.81h3.91v21.94h-3.91V669.81z"
            />
            <path
              class="st2"
              d="M76.55,667.85h3.93v23.9h-3.93V667.85z M78.63,678.06h13.59v3.76H78.63V678.06z M89.87,667.85h3.93v23.9
			h-3.93V667.85z"
            />
            <path
              class="st2"
              d="M99.39,667.85h3.93v23.9h-3.93V667.85z M101.02,667.85h14.05v3.76h-14.05V667.85z M101.02,678.01h12.24v3.75
			h-12.24V678.01z M101.02,687.98h14.05v3.76h-14.05V687.98z"
            />
            <path
              class="st2"
              d="M128.54,667.85h3.93v23.9h-3.93V667.85z M130.43,678.71h7.64c0.64,0,1.19-0.15,1.67-0.44s0.85-0.7,1.12-1.24
			c0.27-0.54,0.4-1.16,0.4-1.86c0-0.71-0.13-1.34-0.39-1.87c-0.26-0.54-0.64-0.95-1.12-1.25c-0.48-0.3-1.04-0.44-1.68-0.44h-7.64
			v-3.76h7.53c1.46,0,2.74,0.3,3.85,0.91c1.11,0.61,1.97,1.47,2.58,2.57c0.61,1.11,0.92,2.39,0.92,3.85s-0.31,2.74-0.92,3.84
			c-0.61,1.1-1.47,1.95-2.58,2.56c-1.11,0.6-2.39,0.9-3.85,0.9h-7.53V678.71z"
            />
            <path
              class="st2"
              d="M149.5,667.85h3.93v23.9h-3.93V667.85z M151.58,678.06h13.59v3.76h-13.59V678.06z M162.83,667.85h3.93v23.9
			h-3.93V667.85z"
            />
            <path
              class="st2"
              d="M179.01,667.85h2.86l8.97,23.9h-4.22l-6.18-17.8l-6.18,17.8h-4.22L179.01,667.85z M174.26,683.45h12.57v3.76
			h-12.57V683.45z"
            />
            <path
              class="st2"
              d="M194.13,667.83h3.93v23.91h-3.93V667.83z M195.76,678.12h8.23c0.55,0,1.03-0.13,1.45-0.4
			c0.42-0.27,0.75-0.65,0.98-1.14s0.35-1.07,0.36-1.73c0-0.65-0.12-1.22-0.35-1.71s-0.56-0.87-0.98-1.14s-0.91-0.4-1.47-0.4h-8.23
			v-3.76h8.35c1.35,0,2.53,0.29,3.55,0.87c1.02,0.58,1.81,1.4,2.38,2.47c0.56,1.06,0.85,2.29,0.85,3.68c0,1.4-0.28,2.64-0.85,3.7
			c-0.56,1.06-1.36,1.88-2.38,2.46c-1.02,0.58-2.21,0.87-3.54,0.87h-8.35V678.12z M201.53,681.15l4.08-0.76l6.28,11.36h-4.72
			L201.53,681.15z"
            />
            <path
              class="st2"
              d="M222.74,667.85h2.86l8.97,23.9h-4.22l-6.18-17.8l-6.18,17.8h-4.22L222.74,667.85z M217.99,683.45h12.57v3.76
			h-12.57V683.45z"
            />
            <path
              class="st2"
              d="M241.45,690.9c-1.29-0.73-2.29-1.76-3.01-3.09c-0.71-1.33-1.07-2.87-1.07-4.63v-6.77
			c0-1.75,0.36-3.29,1.07-4.63c0.71-1.33,1.71-2.36,3.01-3.09c1.29-0.73,2.79-1.09,4.49-1.09s3.19,0.36,4.49,1.09
			s2.29,1.76,3.01,3.09c0.71,1.33,1.07,2.87,1.07,4.63v6.77c0,1.75-0.36,3.29-1.07,4.63c-0.71,1.33-1.71,2.36-3.01,3.09
			c-1.29,0.73-2.79,1.09-4.49,1.09S242.74,691.63,241.45,690.9z M248.31,687.5c0.68-0.39,1.21-0.96,1.59-1.68
			c0.37-0.73,0.56-1.57,0.56-2.52v-7c0-0.95-0.19-1.79-0.56-2.52c-0.37-0.73-0.9-1.29-1.59-1.68s-1.48-0.59-2.37-0.59
			c-0.9,0-1.69,0.2-2.38,0.59s-1.21,0.96-1.59,1.68c-0.37,0.73-0.56,1.57-0.56,2.52v7c0,0.95,0.19,1.79,0.56,2.52
			c0.37,0.73,0.9,1.29,1.59,1.68s1.48,0.59,2.38,0.59C246.83,688.1,247.62,687.9,248.31,687.5z"
            />
            <path
              class="st2"
              d="M259.59,667.85h4.27l10.29,18.39l-0.39,0.43v-18.82h3.81v23.88h-4.31l-10.25-18.11l0.39-0.43v18.54h-3.81
			V667.85z"
            />
            <path class="st2" d="M287,691.75h-4.01v-23.9H287V691.75z" />
            <path
              class="st2"
              d="M295.9,690.92c-1.28-0.72-2.26-1.74-2.94-3.08s-1.03-2.92-1.03-4.75v-6.56c0-1.83,0.34-3.41,1.03-4.75
			c0.68-1.34,1.67-2.37,2.94-3.09c1.28-0.72,2.78-1.08,4.51-1.08c1.43,0,2.74,0.31,3.9,0.92c1.17,0.61,2.14,1.5,2.91,2.65
			c0.77,1.16,1.3,2.52,1.59,4.1h-4.04c-0.2-0.77-0.51-1.44-0.94-2.01c-0.43-0.58-0.93-1.02-1.52-1.32s-1.22-0.46-1.9-0.46
			c-0.91,0-1.7,0.21-2.36,0.62c-0.66,0.41-1.18,1-1.54,1.75s-0.54,1.65-0.54,2.68v6.56c0,1.03,0.18,1.92,0.54,2.68
			c0.36,0.76,0.87,1.34,1.54,1.74c0.66,0.41,1.45,0.61,2.36,0.61c0.69,0,1.33-0.15,1.91-0.44c0.59-0.3,1.09-0.73,1.52-1.3
			c0.43-0.57,0.73-1.25,0.92-2.05h4.04c-0.3,1.58-0.83,2.95-1.6,4.1c-0.77,1.16-1.74,2.04-2.91,2.65c-1.17,0.61-2.46,0.92-3.89,0.92
			C298.68,691.99,297.18,691.63,295.9,690.92z"
            />
            <path
              class="st2"
              d="M94.84,708.24h2.96l4.73,17.34l3.65-17.34h4.06l-5.67,23.9h-3.83l-4.32-16.7l-4.52,16.7h-3.83l-5.67-23.9
			h4.06l3.65,17.34L94.84,708.24z"
            />
            <path
              class="st2"
              d="M120.03,708.24h2.86l8.97,23.9h-4.22l-6.18-17.8l-6.18,17.8h-4.22L120.03,708.24z M115.28,723.83h12.57v3.76
			h-12.57V723.83z"
            />
            <path
              class="st2"
              d="M131.86,708.24h17.19V712h-17.19V708.24z M138.5,710.19h3.91v21.94h-3.91V710.19z"
            />
            <path
              class="st2"
              d="M151.35,708.24h3.93v23.9h-3.93V708.24z M152.98,708.24h14.05V712h-14.05V708.24z M152.98,718.39h12.24v3.75
			h-12.24V718.39z M152.98,728.37h14.05v3.76h-14.05V728.37z"
            />
            <path
              class="st2"
              d="M171.47,708.22h3.93v23.91h-3.93V708.22z M173.1,718.51h8.23c0.55,0,1.03-0.13,1.45-0.4
			c0.42-0.27,0.75-0.65,0.98-1.14s0.35-1.07,0.36-1.73c0-0.65-0.12-1.22-0.35-1.71s-0.56-0.87-0.98-1.14s-0.91-0.4-1.47-0.4h-8.23
			v-3.76h8.35c1.35,0,2.53,0.29,3.55,0.87c1.02,0.58,1.81,1.4,2.38,2.47c0.56,1.06,0.85,2.29,0.85,3.68c0,1.4-0.28,2.64-0.85,3.7
			c-0.56,1.06-1.36,1.88-2.38,2.46c-1.02,0.58-2.21,0.87-3.54,0.87h-8.35V718.51z M178.86,721.53l4.08-0.76l6.28,11.36h-4.72
			L178.86,721.53z"
            />
            <path
              class="st2"
              d="M202.45,708.24h3.93v23.9h-3.93V708.24z M204.34,719.1h7.64c0.64,0,1.19-0.15,1.67-0.44s0.85-0.7,1.12-1.24
			c0.27-0.54,0.4-1.16,0.4-1.86c0-0.71-0.13-1.34-0.39-1.87c-0.26-0.54-0.64-0.95-1.12-1.25c-0.48-0.3-1.04-0.44-1.68-0.44h-7.64
			v-3.76h7.53c1.46,0,2.74,0.3,3.85,0.91c1.11,0.61,1.97,1.47,2.58,2.57c0.61,1.11,0.92,2.39,0.92,3.85s-0.31,2.74-0.92,3.84
			c-0.61,1.1-1.47,1.95-2.58,2.56c-1.11,0.6-2.39,0.9-3.85,0.9h-7.53V719.1z"
            />
            <path
              class="st2"
              d="M230.07,708.24h2.86l8.97,23.9h-4.22l-6.18-17.8l-6.18,17.8h-4.22L230.07,708.24z M225.32,723.83h12.57v3.76
			h-12.57V723.83z"
            />
            <path
              class="st2"
              d="M245.19,708.22h3.93v23.91h-3.93V708.22z M246.82,718.51h8.23c0.55,0,1.03-0.13,1.45-0.4s0.75-0.65,0.98-1.14
			c0.23-0.49,0.35-1.07,0.36-1.73c0-0.65-0.12-1.22-0.35-1.71c-0.23-0.49-0.56-0.87-0.98-1.14s-0.91-0.4-1.47-0.4h-8.23v-3.76h8.35
			c1.35,0,2.53,0.29,3.55,0.87c1.02,0.58,1.81,1.4,2.37,2.47c0.56,1.06,0.85,2.29,0.85,3.68c0,1.4-0.28,2.64-0.85,3.7
			c-0.56,1.06-1.36,1.88-2.38,2.46s-2.21,0.87-3.54,0.87h-8.35V718.51z M252.59,721.53l4.08-0.76l6.28,11.36h-4.72L252.59,721.53z"
            />
            <path
              class="st2"
              d="M267.13,708.24h3.93v23.9h-3.93V708.24z M269.81,722.22l10.06-13.99h4.9l-14.92,19.43L269.81,722.22z
			 M273.79,719.71l3.25-2.45l8.73,14.87h-4.72L273.79,719.71z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SvgLogo;
