import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bounce, Fade } from "react-awesome-reveal";
import "./css/membership.css";
import Styles from "./css/membership.module.css";
import { Helmet } from "react-helmet";
const Membership = () => {
  return (
    <>
      <Helmet>
        <title>Membership</title>
      </Helmet>
      <section>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center">
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div className="sc_layouts_title with_content">
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              <h1 className="sc_layouts_title_caption">
                                ClEOPARK Membership
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Fade direction="up" duration={2000}>
                                <li>
                                  <Link to="/">Home</Link>
                                </li>

                                <li>Membership</li>
                              </Fade>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <article className="post_item_single post_type_page post-91 page type-page status-publish hentry ">
        <div className="post_content entry-content ">
          <div className="wpb-content-wrapper ">
            <section
              className="membership-first-section"
              style={{ marginTop: "0vh" }}
            >
              <div
                className=" row vc_custom_1498994231680   "
                style={{ paddingBottom: "15vh" }}
              >
                <Container>
                  <div
                    className={`col-10 col-lg-5  ms-lg-5 ps-lg-5 ${Styles.membership_container}`}
                  >
                    <Fade cascade direction="left" duration={1000}>
                      <div className="vc_column-inner">
                        <div
                          className="wpb_wrapper"
                          style={{ marginTop: "25vh" }}
                        >
                          <div className="sc_title color_style_default sc_title_default text-center">
                            <h6 className="sc_item_subtitle sc_title_subtitle sc_align_right sc_item_title_style_default ">
                              Explore our membership options
                            </h6>
                            <h2 className="sc_item_title sc_title_title sc_align_right sc_item_title_style_default sc_item_title_tag">
                              INDIVIDUAL SEASON PASS
                            </h2>
                          </div>

                          <div className="wpb_wrapper">
                            <p className="text-end mt-4">
                              from
                              <span className="trx_addons_accent_big">
                                $500
                              </span>
                              <br />
                              Take the time to explore our membership options
                              and immerse yourself in the excitement of our fun
                              world. You can get inspired by the vision of our
                              new facility through our gallery.
                            </p>
                            <p className="text-end ">
                              Discover a world of endless possibilities,
                              featuring an Olympic-sized swimming pool and a kid
                              pool with a water feature. Whether solo or a
                              family looking for adventure, our private park
                              offers all the amenities you need to create
                              incredible memories. So come on over and relax,
                              swim, and soak up the sun like never before!
                            </p>
                          </div>
                        </div>
                        {/* <Link
                          to="/pricing"
                          className="d-flex align-items-center mt-5  ms-auto sc_button color_style_default sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left sc_button_size_small  "
                          >
                          <span className="sc_button_title">view more</span>
                        </Link> */}
                      </div>
                    </Fade>
                  </div>
                </Container>
              </div>
            </section>
            <section>
              <div className="vc_row wpb_row vc_row-fluid vc_custom_1498994565285 vc_row-has-fill py-5">
                <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column_icons_position_left py-5">
                  <div className="vc_column-inner ">
                    <Fade cascade direction="down" duration={1500}>
                      <div className="wpb_wrapper py-5">
                        <div className="sc_action color_style_default scheme_dark sc_action_default pt-5">
                          <h2 className="sc_item_title sc_action_title sc_align_center sc_item_title_style_default">
                            advantages <i>for</i> members
                          </h2>
                          <div className="sc_action_content sc_item_content">
                            <div className="sc_action_item sc_action_item_default sc_action_item_mc"></div>
                          </div>
                        </div>

                        <div className="sc_item_button sc_button_wrap sc_align_center">
                          <Link
                            href="#."
                            className="sc_button color_style_default sc_button_alternative sc_button_size_large sc_button_icon_left sc_button_size_small"
                          >
                            <span className="sc_button_text">
                              <span className="sc_button_title">
                                view details
                              </span>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Container>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column_icons_position_left">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div
                          className="vc_empty_space  height_large"
                          style={{ height: "32px" }}
                        >
                          <span className="vc_empty_space_inner"></span>
                        </div>

                        <div className=" mx-auto justify-content-center justify-content-lg-between  d-flex  flex-column-reverse flex-lg-row align-items-center">
                          {" "}
                          <div className="col-10 col-lg-5 mb-5 mb-lg-0">
                            <div className="vc_column-inner">
                              <Fade cascade direction="left" duration={1000}>
                                <div className="wpb_wrapper">
                                  <div className="sc_price color_style_default sc_price_horizontal">
                                    <div className="sc_price_content sc_item_content">
                                      <div className="sc_price_item sc_price_item_horizontal">
                                        <div className="sc_price_item_info">
                                          <div className="price-header">
                                            <div className="sc_price_item_price">
                                              <span className="sc_price_item_price_value">
                                                $900
                                              </span>
                                            </div>
                                            <h3 className="sc_price_item_title">
                                              <span>Family Season Pass</span>
                                            </h3>
                                          </div>
                                          <div className="sc_price_item_details">
                                            <ul className="trx_addons_list trx_addons_list_square">
                                              <li>Up to 5 people</li>
                                              <li>3 month</li>
                                              <li>Unlimited Visits</li>
                                              <li>Drinks Discount</li>
                                            </ul>
                                          </div>
                                          <Link
                                            href="#."
                                            className="sc_price_item_link sc_button"
                                          >
                                            learn more
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="sc_price_item sc_price_item_horizontal">
                                        <div className="sc_price_item_info">
                                          <div className="price-header">
                                            <div className="sc_price_item_price">
                                              <span className="sc_price_item_price_value">
                                                $700
                                              </span>
                                            </div>
                                            <h3 className="sc_price_item_title">
                                              <span>Couple Season Pass</span>
                                            </h3>
                                          </div>
                                          <div className="sc_price_item_details">
                                            <ul className="trx_addons_list trx_addons_list_square">
                                              <li>2 people</li>
                                              <li>3 month</li>
                                              <li>Unlimited Visits</li>
                                              <li>Drinks Discount</li>
                                            </ul>
                                          </div>
                                          <Link
                                            href="#"
                                            className="sc_price_item_link sc_button"
                                          >
                                            learn more
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="sc_price_item sc_price_item_horizontal">
                                        <div className="sc_price_item_info">
                                          <div className="price-header">
                                            <div className="sc_price_item_price">
                                              <span className="sc_price_item_price_value">
                                                $500
                                              </span>
                                            </div>
                                            <h3 className="sc_price_item_title">
                                              <span>
                                                Individual Season Pass
                                              </span>
                                            </h3>
                                          </div>
                                          <div className="sc_price_item_details">
                                            <ul className="trx_addons_list trx_addons_list_square">
                                              <li>1 person</li>
                                              <li>3 month</li>
                                              <li>Unlimited Visits</li>
                                              <li>Drinks Discount</li>
                                            </ul>
                                          </div>
                                          <Link
                                            href="#"
                                            className="sc_price_item_link sc_button"
                                          >
                                            learn more
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fade>
                            </div>
                          </div>
                          <div className="col-10 col-lg-5 mb-5 mb-lg-0">
                            <div className="vc_column-inner">
                              <Fade cascade direction="right" duration={1000}>
                                <div className="wpb_wrapper">
                                  <div className="sc_title color_style_default sc_title_default  ">
                                    <h6 className="sc_item_subtitle sc_title_subtitle sc_align_left sc_item_title_style_default">
                                      Our packages
                                    </h6>
                                    <h2 className="sc_item_title sc_title_title sc_align_left sc_item_title_style_default sc_item_title_tag  ">
                                      Explore our membership options
                                    </h2>
                                    <div className="sc_item_descr sc_title_descr sc_align_left my-5">
                                      <p>
                                        Get ready to experience the thrill of a
                                        lifetime! Our exclusive membership
                                        options offer endless possibilities for
                                        you to immerse yourself in infinite
                                        excitement. Don't wait any longer, join
                                        us now and dive into the adventure of a
                                        lifetime!
                                      </p>
                                    </div>
                                    <div className="sc_item_button sc_button_wrap sc_align_left mt-0">
                                      <Link
                                        href="/about-us-style-2/"
                                        className="sc_button color_style_default sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left sc_button_size_small  "
                                      >
                                        <span className="sc_button_title">
                                          view more
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Fade>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </section>
          </div>
        </div>
      </article>
    </>
  );
};

export default Membership;
