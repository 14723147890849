import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Fade, Flip } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Facilities = () => {
  const isButtonVisible = window.location.href.includes("/facilities");

  return (
    <div className="vc_row wpb_row vc_row-fluid sc_layouts_485 vc_row-no-padding vc_row-o-equal-height vc_row-flex">
      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6 vc_col-has-fill sc_layouts_column_icons_position_left">
        <div className="vc_column-inner vc_custom_1502977080781 pt-5">
          <div className="wpb_wrapper pt-5 ">
            <div
              className="sc_content color_style_default sc_content_default sc_content_width_1_3 sc_float_right sc_align_right sc_margin_medium pt-0 pb-5 py-lg-0 pt-lg-5"
              style={{ margin: "0 4%  " }}
            >
              <Fade cascade delay={100} duration={1500}>
                <div className="sc_content_container ">
                  <div className="sc_title color_style_default sc_title_default">
                    <h6 className="sc_item_subtitle sc_title_subtitle sc_align_right sc_item_title_style_default py-3">
                      We have created an environment where your comfort and
                      enjoyment are our top priority.
                    </h6>
                    <h2 className="sc_item_title sc_title_title sc_align_right sc_item_title_style_default sc_item_title_tag">
                      our facilities
                    </h2>
                  </div>

                  <div className="sc_title color_style_default sc_title_default  vc_custom_1502884237192">
                    <div className="sc_item_descr sc_title_descr sc_align_right mt-5">
                      <p className="mb-0  ">
                        Our park facilities are designed to provide everything
                        you need to have a great time and make a splash with a
                        big smile on your face!
                      </p>
                    </div>

                    <div className="sc_item_button sc_button_wrap sc_align_right mt-5">
                      <Link
                        to="facilities"
                        className="sc_button color_style_default sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left sc_button_icon_left sc_button_size_small"
                      >
                        <span className="sc_button_title">
                          view all facilities
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6 vc_col-has-fill sc_layouts_column_icons_position_left">
        <div className="vc_column-inner vc_custom_1568101894237">
          <div className="wpb_wrapper">
            <div className="sc_services color_style_default sc_services_accent sc_services_featured_top">
              <div className="sc_services_columns_wrap sc_item_columns sc_item_columns_6 trx_addons_columns_wrap columns_padding_bottom">
                <div className="trx_addons_column-1_2">
                  {" "}
                  <div className="sc_services_item with_content with_icon sc_services_item_featured_top">
                    <Flip duration={1500}>
                      <Link to="/">
                        <FontAwesomeIcon
                          icon="fa-light fa-solid fa-shop-lock"
                          className="sc_services_item_icon   "
                        />
                      </Link>
                    </Flip>
                    <div className="sc_services_item_info">
                      <div className="sc_services_item_header">
                        <h6 className="sc_services_item_title">
                          <Link to="/">LOCKERS </Link>
                        </h6>
                        {isButtonVisible ? (
                          <p className="pt-5">
                            our convenient lockers are available to keep your
                            valuables safe.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trx_addons_column-1_2">
                  <div className="sc_services_item with_content with_icon sc_services_item_featured_top">
                    <Flip duration={1500}>
                      <Link
                        to="/"
                        className="sc_services_item_icon icon-icon2"
                      ></Link>
                    </Flip>
                    <div className="sc_services_item_info">
                      <div className="sc_services_item_header">
                        <h6 className="sc_services_item_title">
                          <Link to="/">Showers </Link>
                        </h6>
                        {isButtonVisible ? (
                          <p className="pt-5">
                            Cleopark provides the perfect post-swim retreat,
                            with refreshing showers and luxuriating in the
                            sauna.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trx_addons_column-1_2">
                  <div className="sc_services_item with_content with_icon sc_services_item_featured_top">
                    <Flip duration={1500}>
                      {" "}
                      <Link
                        to="/"
                        id="sc_services_426655141_icon-icon3"
                        className="sc_services_item_icon icon-icon3"
                      ></Link>
                    </Flip>
                    <div className="sc_services_item_info">
                      <div className="sc_services_item_header">
                        <h6 className="sc_services_item_title">
                          <Link to="/">Food and Drinks Service</Link>
                        </h6>
                        {isButtonVisible ? (
                          <p className="pt-5">
                            Unwind with a cool beverage by the pool or savor a
                            delectable meal to satisfy your hunger.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="trx_addons_column-1_2">
                  <div className="sc_services_item with_content with_icon sc_services_item_featured_top">
                    <Flip duration={1500}>
                      {" "}
                      <Link to="/">
                        <FontAwesomeIcon
                          icon="fa-solid fa-house-chimney-medical"
                          className="sc_services_item_icon  "
                        />
                      </Link>
                    </Flip>
                    <div className="sc_services_item_info">
                      <div className="sc_services_item_header">
                        <h6 className="sc_services_item_title">
                          <Link to="/">Clinic</Link>
                        </h6>
                        {isButtonVisible ? (
                          <p className="pt-5">
                            professionals trained team to provide immediate
                            assistance and ensure your well-being during your
                            waterpark visit.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
