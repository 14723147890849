import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bounce, Fade, Zoom } from "react-awesome-reveal";
import "./css/water-slides.css";
import img1 from "./images/1-SCORPIO-ATTACK.webp";
import img2 from "./images/2-QUEENS-TROPHY.webp";
import img3 from "./images/3-YOUNG-OAISIS.webp";
import img4 from "./images/4-SOLAR-BOAT.webp";
import img5 from "./images/5-SNAKE-TERROR.webp";
import img6 from "./images/6-LAST-TEMPLE.webp";
import img7 from "./images/7-NEW-TODDLER-POOL.webp";
import { Helmet } from "react-helmet";
const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};

const WaterSlides = () => {
  const tiltRef1 = useRef(null);
  const tiltRef2 = useRef(null);
  const tiltRef3 = useRef(null);
  const tiltRef4 = useRef(null);
  const tiltRef5 = useRef(null);
  const tiltRef6 = useRef(null);
  const tiltRef7 = useRef(null);
  const tiltRef8 = useRef(null);
  const tiltRef9 = useRef(null);
  const tiltRef10 = useRef(null);
  const tiltHandler1 = createTiltHandler(tiltRef1);
  const tiltHandler2 = createTiltHandler(tiltRef2);
  const tiltHandler3 = createTiltHandler(tiltRef3);
  const tiltHandler4 = createTiltHandler(tiltRef4);
  const tiltHandler5 = createTiltHandler(tiltRef5);
  const tiltHandler6 = createTiltHandler(tiltRef6);
  const tiltHandler7 = createTiltHandler(tiltRef7);
  const tiltHandler8 = createTiltHandler(tiltRef8);
  const tiltHandler9 = createTiltHandler(tiltRef9);
  const tiltHandler10 = createTiltHandler(tiltRef10);

  return (
    <>
      <Helmet>
        <title>Water Slides</title>
      </Helmet>
      <section>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center">
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div className="sc_layouts_title with_content">
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              <h1 className="sc_layouts_title_caption">
                                CLEOPARK Water Slides
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Fade direction="up" duration={2000}>
                                {" "}
                                <li>
                                  <Link to="/">Home</Link>
                                </li>
                                <li>Water Slides</li>
                              </Fade>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="first-section">
        <Container>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            className="vc_row wpb_row vc_row-fluid vc_custom_1498925128974 vc_row-has-fill"
          >
            <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column_icons_position_left">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="sc_services color_style_default sc_services_default sc_services_featured_top ">
                    <Fade cascade direction="up" duration={500} delay={0}>
                      <h6 className="sc_item_subtitle sc_services_subtitle sc_align_center sc_item_title_style_default mb-3">
                        Come on ….. and have a BLAST!
                      </h6>
                      <h2 className="sc_item_title sc_services_title sc_align_center sc_item_title_style_default sc_item_title_tag mb-5">
                        CLEOPARK Water Slides
                      </h2>
                    </Fade>
                    <div className="sc_services_columns_wrap sc_item_columns sc_item_columns_3 trx_addons_columns_wrap columns_padding_bottom justify-content-center row mx-auto pt-5 ">
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top  ">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef1}
                              onMouseMove={tiltHandler1.handleMove}
                              onMouseOut={tiltHandler1.handleMouseOut}
                              onMouseDown={tiltHandler1.handleMouseDown}
                              onMouseUp={tiltHandler1.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img1}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="SCORPIO ATTACK"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">SCORPIO ATTACK</a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  Get ready to mix the excitement of sliding
                                  down our River slide surrounded by mega
                                  scorpions with the thrill of knowing that
                                  these giant creatures are chasing you!
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef2}
                              onMouseMove={tiltHandler2.handleMove}
                              onMouseOut={tiltHandler2.handleMouseOut}
                              onMouseDown={tiltHandler2.handleMouseDown}
                              onMouseUp={tiltHandler2.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img2}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt=" Queens Trophy"
                              />
                            </div>

                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">
                                    Queens Trophy
                                    <br />
                                  </a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  An adrenaline-filled experience with our
                                  incredible 2-lane adventurer slides! As you
                                  slide down, you will be transported to the
                                  ancient world of Egypt, welcoming you with
                                  open arms.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          {" "}
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef7}
                              onMouseMove={tiltHandler7.handleMove}
                              onMouseOut={tiltHandler7.handleMouseOut}
                              onMouseDown={tiltHandler7.handleMouseDown}
                              onMouseUp={tiltHandler7.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img3}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="YOUNG PHARAOHS OASIS"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">YOUNG PHARAOHS OASIS</a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  The children's area is a fun-filled oasis with
                                  games, slides, and water attractions,
                                  including a family slide, water playground,
                                  and baby pool. All attractions are heated
                                  during winter.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          {" "}
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef3}
                              onMouseMove={tiltHandler3.handleMove}
                              onMouseOut={tiltHandler3.handleMouseOut}
                              onMouseDown={tiltHandler3.handleMouseDown}
                              onMouseUp={tiltHandler3.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img4}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="SOLAR BOAT"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">SOLAR BOAT</a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  The sidewinder slide is decorated like a boat;
                                  it is breathtaking for those seeking thrilling
                                  action.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef4}
                              onMouseMove={tiltHandler4.handleMove}
                              onMouseOut={tiltHandler4.handleMouseOut}
                              onMouseDown={tiltHandler4.handleMouseDown}
                              onMouseUp={tiltHandler4.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img5}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="SNAKE TERROR"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">SNAKE TERROR</a>
                                </h6>
                              </div>
                              <p className="mt-3 fs-4 text-secondary">
                                It is an exhilarating adventure as you enter the
                                snake's jaw, slide through the twisting and
                                turning space bowl, and get swallowed up.
                              </p>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          {" "}
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef5}
                              onMouseMove={tiltHandler5.handleMove}
                              onMouseOut={tiltHandler5.handleMouseOut}
                              onMouseDown={tiltHandler5.handleMouseDown}
                              onMouseUp={tiltHandler5.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img6}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="LAST TEMPLE"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">LAST TEMPLE</a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  Challenge your friends and family to an
                                  exciting competition with multiple lanes to
                                  choose from.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>

                      <div className="trx_addons_column-1_3 ">
                        <Zoom duration={2000}>
                          {" "}
                          <div className="sc_services_item with_content with_image sc_services_item_featured_top">
                            <div
                              className="post_featured with_thumb hover_icon sc_services_item_thumb px-4"
                              ref={tiltRef6}
                              onMouseMove={tiltHandler6.handleMove}
                              onMouseOut={tiltHandler6.handleMouseOut}
                              onMouseDown={tiltHandler6.handleMouseDown}
                              onMouseUp={tiltHandler6.handleMouseUp}
                              style={{ transition: "none" }}
                            >
                              <img
                                loading="lazy"
                                src={img7}
                                className="attachment-playa-thumb-med size-playa-thumb-med wp-post-image"
                                alt="A NEW TODDLER POOL"
                              />
                            </div>
                            <div className="sc_services_item_info">
                              <div className="sc_services_item_header">
                                <h6 className="sc_services_item_title">
                                  <a href="#.">A NEW TODDLER POOL</a>
                                </h6>
                                <p className="mt-3 fs-4 text-secondary">
                                  This area has been specifically created to
                                  offer children a secure and pleasant
                                  environment that protects their delicate skin
                                  from sunburn. The play area boasts oversized,
                                  specially made soft foam toys and water jets
                                  that are sure to entertain kids for hours. To
                                  add to the overall experience, the play area
                                  is surrounded by traditional Egyptian boats.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default WaterSlides;
