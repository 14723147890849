import React from "react";
import lockerImg from "./images/locker.png";
import showerImg from "./images/shower.png";
import foodAndDrinkImg from "./images/food-and-drink.png";
import clinicImg from "./images/clinic.png";
import giftShopImg from "./images/gift-shop.png";
import "./css/facilities.css";
import { Bounce, Fade, Flip } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const FacilitiesPage = () => {
  return (
    <>
      <Helmet>
        <title>Facilities</title>
      </Helmet>
      <section>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div
                  id="sc_content_1271549084"
                  className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center"
                >
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div
                        id="sc_layouts_title_520295187"
                        className="sc_layouts_title with_content"
                      >
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              <h1 className="sc_layouts_title_caption">
                                CLEOPARK Facilities
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Fade direction="up" duration={2000}>
                                <li>
                                  <Link to="/">Home</Link>
                                </li>
                                <li>Facilities</li>
                              </Fade>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="facilities" className="mb-5 pb-5">
        <div className="col-10 mx-auto py-5 mb-5">
          <Fade cascade direction="up" duration={1000} delay={0}>
            <div
              className="col-lg-6 col-12 mx-auto"
              style={{ marginTop: "24vh" }}
            >
              <h6 className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_title_style_default">
                More than just a pool
              </h6>
              <h2 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag pb-3">
                Your Ultimate Water Park Experience
              </h2>
              <p className="text-center  fs-4">
                Discover a world of endless possibilities. We have created an
                environment where your comfort and enjoyment are our top
                priority.
              </p>
            </div>
          </Fade>
        </div>
        <div className="row col-10 col-lg-12 mx-auto">
          <div className="container sc_services color_style_default sc_services_light sc_services_featured_top">
            <div className=" d-flex justify-content-center flex-wrap px-5 mx-5">
              <div className="col-12 col-lg-3 mx-5 mb-5">
                <div className="sc_services_item without_content with_icon sc_services_item_featured_top">
                  <a
                    href="#."
                    className="sc_services_item_icon mx-auto d-flex justify-content-center align-items-center"
                  >
                    <Flip duration={1500}>
                      <img
                        src={lockerImg}
                        alt="locker"
                        className="d-block m-auto"
                      />
                    </Flip>
                  </a>
                  <div className="sc_services_item_info mt-3">
                    <div className="sc_services_item_header">
                      <h6 className="sc_services_item_title px-1">
                        <a href="#.">Locker</a>
                      </h6>
                      <p className="mt-3 fs-4">
                        our convenient lockers are available to keep your
                        valuables safe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mx-5 mb-5">
                <div className="sc_services_item without_content with_icon sc_services_item_featured_top">
                  <a
                    href="#."
                    className="sc_services_item_icon mx-auto d-flex justify-content-center align-items-center"
                  >
                    <Flip duration={1500}>
                      {" "}
                      <img
                        src={showerImg}
                        alt="Shower And Sauna"
                        className="d-block m-auto"
                      />
                    </Flip>
                  </a>
                  <div className="sc_services_item_info mt-3">
                    <div className="sc_services_item_header">
                      <h6 className="sc_services_item_title px-1">
                        <a href="#.">Shower </a>
                      </h6>
                      <p className="mt-3 fs-4">
                        Cleopark provides the perfect post-swim retreat, with
                        refreshing showers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mx-5 mb-5">
                <div className="sc_services_item without_content with_icon sc_services_item_featured_top">
                  <a
                    href="#."
                    className="sc_services_item_icon mx-auto d-flex justify-content-center align-items-center"
                  >
                    <Flip duration={1500}>
                      {" "}
                      <img
                        src={foodAndDrinkImg}
                        alt="FOOD AND DRINKS SERVICE"
                        className="d-block m-auto"
                      />
                    </Flip>
                  </a>
                  <div className="sc_services_item_info mt-3">
                    <div className="sc_services_item_header">
                      <h6 className="sc_services_item_title px-1">
                        <a href="#.">FOOD AND DRINKS SERVICE</a>
                      </h6>
                      <p className="mt-3 fs-4">
                        Unwind with a cool beverage by the pool or savor a
                        delectable meal to satisfy your hunger.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mx-5 mb-5">
                <div className="sc_services_item without_content with_icon sc_services_item_featured_top">
                  <a
                    href="#."
                    className="sc_services_item_icon mx-auto d-flex justify-content-center align-items-center"
                  >
                    <Flip duration={1500}>
                      {" "}
                      <img
                        src={clinicImg}
                        alt="CLINIC"
                        className="d-block m-auto"
                      />
                    </Flip>
                  </a>
                  <div className="sc_services_item_info mt-3">
                    <div className="sc_services_item_header">
                      <h6 className="sc_services_item_title px-1">
                        <a href="#.">CLINIC</a>
                      </h6>
                      <p className="mt-3 fs-4">
                        A professional doctor will be available on-call to
                        assist you with any medical concerns during your visit
                        to the waterpark.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mx-5 mb-5">
                <div className="sc_services_item without_content with_icon sc_services_item_featured_top">
                  <a
                    href="#."
                    className="sc_services_item_icon mx-auto d-flex justify-content-center align-items-center"
                  >
                    <Flip duration={1500}>
                      {" "}
                      <img
                        src={giftShopImg}
                        alt="GIFT SHOP"
                        className="d-block m-auto"
                      />
                    </Flip>
                  </a>
                  <div className="sc_services_item_info mt-3">
                    <div className="sc_services_item_header">
                      <h6 className="sc_services_item_title px-1">
                        <a href="#.">GIFT SHOP</a>
                      </h6>
                      <p className="mt-3 fs-4">
                        Find the perfect gift for everyone to have a
                        long-lasting memory
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FacilitiesPage;
