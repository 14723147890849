import React, { useState } from "react";
import { Container, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Fancybox from "../../components/fancybox-library/fancybox";
import styles from "../home/css/gallery.module.css";
import { useSpring, animated } from "react-spring";
import { Bounce, Zoom } from "react-awesome-reveal";
import "./css/gallery.css";
import galleryImg1 from "../../components/images/gallery1.webp";
import galleryImg2 from "../../components/images/gallery2.webp";
import galleryImg3 from "../../components/images/gallery3.webp";
import galleryImg4 from "../../components/images/gallery4.webp";
import galleryImg5 from "../../components/images/gallery5.webp";
import galleryImg6 from "../../components/images/gallery6.webp";
import galleryImg7 from "../../components/images/gallery7.webp";
import galleryImg8 from "../../components/images/gallery8.webp";
import galleryImg9 from "../../components/images/gallery9.webp";
import galleryImg10 from "../../components/images/gallery10.webp";
import galleryImg11 from "../../components/images/gallery11.webp";
import galleryImg12 from "../../components/images/gallery12.webp";
import galleryImg13 from "../../components/images/gallery13.webp";
import galleryImg14 from "../../components/images/gallery14.webp";
import galleryImg15 from "../../components/images/gallery15.webp";
import galleryImg16 from "../../components/images/gallery16.webp";
import { Helmet } from "react-helmet";
const Gallery = () => {
  const [isHidden, setIsHidden] = useState(true);
  const navigate = useNavigate();
  const toggleView = () => {
    setTimeout(() => {
      setIsHidden(!isHidden);
    }, 200);
  };

  // Define the spring animation
  const animation = useSpring({
    opacity: isHidden ? 1 : 1,
    transform: `translateY(${isHidden ? "50px" : "0px"})`,
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 770,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>Gallery</title>
      </Helmet>
      <section id="less">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div
                  id="sc_content_1271549084"
                  className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center"
                >
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div
                        id="sc_layouts_title_520295187"
                        className="sc_layouts_title with_content"
                      >
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              {" "}
                              <h1 className="sc_layouts_title_caption">
                                CLEOPARK Gallery
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Zoom direction="up" duration={2000}>
                                <li>
                                  <Link to="/">Home</Link>
                                </li>

                                <li>Gallery</li>
                              </Zoom>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="first-section mb-5 pb-5">
        <Container>
          <div className="sc_title color_style_default sc_title_default mb-5 pb-5">
            <Zoom cascade direction="up" duration={1000} delay={0}>
              {" "}
              <h2 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                <NavLink
                  to="#."
                  className={`text-main  ${styles.titleLink}`}
                  style={{ color: "#00516f" }}
                >
                  OUR Gallery
                </NavLink>
              </h2>
            </Zoom>
          </div>

          <div
            className={styles.gallery}
            style={{ margin: "auto", textAlign: "center" }}
          >
            <Fancybox>
              <div className="d-block">
                <div className="gallery-page d-flex flex-wrap">
                  <a
                    data-fancybox="gallery"
                    href={galleryImg1}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii  ${styles.egplayaelement0a}`}
                      href={galleryImg1}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img alt="gallery" src={galleryImg1} />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg2}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg2}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg2}
                        width="368"
                        height="368"
                      />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                    href={galleryImg3}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg3}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg3}
                        width="368"
                        height="368"
                      />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg4}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg4}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg4}
                        width="368"
                        height="368"
                      />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg5}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg5}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img alt="gallery" src={galleryImg5} />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg6}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg6}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      {" "}
                      <img alt="gallery" src={galleryImg6} />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg7}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg7}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      <img alt="gallery" src={galleryImg7} />
                    </Zoom>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg8}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg8}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Zoom duration={2000} delay={0}>
                      <img alt="gallery" src={galleryImg8} />
                    </Zoom>
                  </a>
                </div>
                <div className="container-more d-block">
                  <animated.div
                    className={`items-container ${
                      isHidden ? "hidden" : "visible mb-2"
                    }`}
                    style={animation}
                  >
                    <Fancybox>
                      <div className="gallery-page d-flex flex-wrap">
                        <a
                          id="more"
                          data-fancybox="gallery"
                          href={galleryImg9}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii  ${styles.egplayaelement0a}`}
                            href={galleryImg9}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img alt="gallery" src={galleryImg9} />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg10}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg10}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img
                              alt="gallery"
                              src={galleryImg10}
                              width="368"
                              height="368"
                            />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                          href={galleryImg11}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg11}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img
                              alt="gallery"
                              src={galleryImg11}
                              width="368"
                              height="368"
                            />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg12}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg12}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img
                              alt="gallery"
                              src={galleryImg12}
                              width="368"
                              height="368"
                            />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg13}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg13}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img alt="gallery" src={galleryImg13} />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg14}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg14}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img alt="gallery" src={galleryImg14} />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg15}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg15}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img alt="gallery" src={galleryImg15} />
                          </Zoom>
                        </a>
                        <a
                          data-fancybox="gallery"
                          href={galleryImg16}
                          className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition col-11 col-lg-3 mx-auto  p-gallery`}
                        >
                          <a
                            className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                            href={galleryImg16}
                          >
                            <i className="eg-icon-plus"></i>
                          </a>
                          <Zoom duration={2000} delay={0}>
                            <img alt="gallery" src={galleryImg16} />
                          </Zoom>
                        </a>
                      </div>
                    </Fancybox>
                  </animated.div>
                  <button
                    className="bg-main sc_button_icon_left sc_button_size_small d-block  m-auto text-center bg-clepark mt-5"
                    onClick={() => {
                      toggleView();
                      !isHidden ? scrollToTop() : navigate("#more");
                    }}
                  >
                    {isHidden ? "View More" : "View Less"}
                  </button>
                </div>
              </div>
            </Fancybox>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Gallery;
