import React from "react";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import Facilities from "./Facilities";
import Gallery from "./Gallery";
import WelcomeSection from "./WelcomeSection";
import Map from "../../components/Map";
import "./css/index.css";
import { Helmet } from "react-helmet";
import { Beach } from "./Beach";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>CLEOPARK | Home</title>
      </Helmet>
      <Banner />
      <WelcomeSection />
      <AboutUs />
      <Facilities />
      <Beach />
      <Map />
      <Gallery />

      {/*<Offer /> <Contacts />*/}
    </>
  );
};

export default Home;
