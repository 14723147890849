import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import aboutImg from "./images/about.webp";
import { Fade } from "react-awesome-reveal";

const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};

const WelcomeSection = () => {
  const tiltRef1 = useRef(null);

  const tiltHandler1 = createTiltHandler(tiltRef1);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section style={{ marginTop: "12vh" }} id="welcome-section ">
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1499012876613 vc_row-has-fill bg-light py-5">
        <Container>
          <div className=" d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center py-5 my-3">
            <div className="   py-4    ps-lg-2 pe-lg-5 mt-5 mt-lg-0 col-10 col-md-12 col-lg-6">
              <Fade direction="left" duration={1000}>
                <div className="sc_title color_style_default sc_title_default">
                  <h6 className="sc_item_subtitle sc_title_subtitle text-left sc_item_title_style_default">
                    A Never-ending enjoyment!
                  </h6>
                  <h2 className="sc_item_title sc_title_title text-left sc_item_title_style_default sc_item_title_tag">
                    The Pharaonic water park
                  </h2>
                </div>

                <div className="wpb_text_column wpb_content_element py-4 pe-5  ">
                  <div className="wpb_wrapper">
                    <p className="text-left">
                      There are plenty of fun activities and thrilling rides to
                      experience at Cleopark, ensuring you will never run out of
                      things to do.
                    </p>
                  </div>
                </div>

                <div className="sc_item_button sc_button_wrap text-left">
                  <NavLink
                    onClick={() => scrollToSection("about-us")}
                    to="/"
                    className="sc_button color_style_default sc_button_default sc_button_size_normal sc_button_icon_left sc_button_hover_slide_left"
                  >
                    <span className="sc_button_text">
                      <span className="sc_button_title">view More</span>
                    </span>
                  </NavLink>
                </div>
              </Fade>
            </div>

            <div
              className="col-10 col-md-12 col-lg-5  "
              ref={tiltRef1}
              onMouseMove={tiltHandler1.handleMove}
              onMouseOut={tiltHandler1.handleMouseOut}
              onMouseDown={tiltHandler1.handleMouseDown}
              onMouseUp={tiltHandler1.handleMouseUp}
              style={{ transition: "none" }}
            >
              <img
                src={aboutImg}
                alt="about"
                loading="lazy"
                className="d-lg-none "
              />

              <Fade
                cascade
                direction="right"
                duration={1000}
                className="d-none d-lg-block"
              >
                <img src={aboutImg} alt="about" loading="lazy" />
              </Fade>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default WelcomeSection;
