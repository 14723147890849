import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col } from "react-bootstrap";
import slider1 from "./images/s1.webp";
import slider2 from "./images/s2.webp";
import slider3 from "./images/s3.webp";
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";
import logoImg from "../../components/images/logo.png";
const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const settings = {
    className: "slider-wrapper swiper-wrapper col-6",
    dots: true,
    arrows: false,
    infinite: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section style={{ marginTop: "0vh" }} id="never-section">
      <div
        data-vc-full-width="true"
        data-vc-full-width-init="false"
        className="vc_row wpb_row vc_row-fluid bg-never-section vc_row-has-fill"
      >
        <div
          className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column_icons_position_left"
          id="about-us"
        >
          <div className="vc_column-inner">
            <div
              className="wpb_wrapper  "
              style={{ marginTop: isMobile ? "5vh" : "15vh" }}
            >
              <div className="sc_content color_style_default sc_content_grey sc_float_center container">
                <div className="sc_content_container">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid d-flex flex-column flex-lg-row align-items-center col-lg-10 mx-lg-auto">
                    <Col className="col-10 col-lg-5 px-0 ">
                      <Fade direction="left" duration={2000}>
                        <Slider {...settings}>
                          <div>
                            <img src={slider1} alt="slider 1" />
                          </div>
                          <div>
                            <img src={slider2} alt="slider 2" />
                          </div>
                          <div>
                            <img src={slider3} alt="slider 3" />
                          </div>
                        </Slider>
                      </Fade>
                    </Col>
                    <div className="  mt-5 mt-lg-0 pt-5 pt-lg-0 vc_col-md-offset-1   w-mobile mx-lg-5 pt-lg-5 col-10 col-lg-5">
                      <Fade direction="right" duration={2000}>
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="sc_title color_style_default sc_title_default">
                              {/*    <h6 className="sc_item_subtitle sc_title_subtitle sc_align_left sc_item_title_style_default">
                                Never ending enjoyment
                              </h6>
                              <h2 className="sc_item_title sc_title_title sc_align_left sc_item_title_style_default sc_item_title_tag">
                                About ClEOPARK
                              </h2> */}
                              <div className="sc_item_descr sc_title_descr sc_align_left">
                                <h6
                                  className="sc_item_subtitle sc_title_subtitle sc_align_left sc_item_title_style_default mb-5  fs-4"
                                  style={{ fontStyle: "normal" }}
                                >
                                  <span className="me-2 fs-3">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-masks-theater   "
                                      style={{ color: "#ffba00" }}
                                    />
                                  </span>{" "}
                                  FAMILY WATERPARK
                                </h6>
                                <img
                                  alt="logo"
                                  src={logoImg}
                                  className="logo_bg"
                                />
                                <p>
                                  Cleo Park, the first water park in Sharm, was
                                  established in 2006. The park is located in
                                  Naama Bay, providing an awe-inspiring view of
                                  the bay. The park's designers opted for a
                                  pharaonic theme, which is evident in its
                                  overall design.
                                </p>
                                <p>
                                  Cleo Park Water Park offers something for
                                  everyone, from sun-seekers to fun-seekers, as
                                  it has several attractions. Animation team to
                                  entertain, slides, rides & fabulous kids' area
                                  oasis, and ideally to be visited around the
                                  year, even during the winter since the heated
                                  water is available.
                                </p>
                                {/*<h6
                                  className="sc_item_subtitle sc_title_subtitle sc_align_left sc_item_title_style_default mb-2 fs-5"
                                  style={{ fontStyle: "normal" }}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-water-ladder "
                                    className="fs-4 pe-2"
                                  />
                                  Our Pool
                                  <span
                                    className="d-d-inline-block ps-2"
                                    style={{ color: "#ffba00" }}
                                  >
                                    Facilities
                                  </span>
                                </h6>
                                <p>
                                  Cleo Park, the first water park in Sharm, was
                                  established in 2006. The park is located in
                                  Naama Bay, providing an awe-inspiring view of
                                  the bay. The park's designers opted for a
                                  pharaonic theme, which is evident in its
                                  overall design.
                                </p>
                                <p>
                                  Cleo Park Water Park offers something for
                                  everyone, from sun-seekers to fun-seekers, as
                                  it has several attractions. Animation team to
                                  entertain, slides, rides & fabulous kids' area
                                  oasis, and ideally to be visited around the
                                  year, even during the winter since the heated
                                  water is available.
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
