import React, { useRef } from "react";
import { Bounce, Fade } from "react-awesome-reveal";
import { Container } from "react-bootstrap";
import { a, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import retaurantImg1 from "./images/amoun.jpg";
import retaurantImg2 from "./images/pizza.jpg";
import { Helmet } from "react-helmet";
const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};
const FoodAndDrinks = () => {
  const tiltRef1 = useRef(null);
  const tiltRef2 = useRef(null);

  const tiltHandler1 = createTiltHandler(tiltRef1);
  const tiltHandler2 = createTiltHandler(tiltRef2);

  return (
    <>
      <Helmet>
        <title>Food & Drinks</title>
      </Helmet>
      <section>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div
                  id="sc_content_1271549084"
                  className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center"
                >
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div
                        id="sc_layouts_title_520295187"
                        className="sc_layouts_title with_content"
                      >
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              <h1 className="sc_layouts_title_caption">
                                Food & Drinks
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Fade direction="up" duration={2000}>
                                <li>
                                  <a to="/">Home</a>
                                </li>

                                <li>Food & Drinks</li>
                              </Fade>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "12vh" }} id="welcome-section ">
        <div className=" bg-light py-5">
          <Container>
            <article className="py-5 my-5">
              <Fade cascade direction="up" duration={1000} delay={0}>
                {" "}
                <h6 className="sc_item_subtitle sc_services_subtitle sc_align_center sc_item_title_style_default mb-3">
                  At Cleopark each restaurant is stylish in its own way.
                </h6>
                <h2 className="sc_item_title sc_services_title sc_align_center sc_item_title_style_default sc_item_title_tag mb-5">
                  Sip & Socialize <br />
                  Where Food and Drinks Unite
                </h2>
              </Fade>
              {/*R1 */}
              <div className="col-11 col-lg-12 mx-auto d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center py-5 my-5">
                <div className="col-11 col-lg-6 py-4 pe-lg-5 ps-lg-2   ">
                  <Fade direction="left" duration={1000} delay={100}>
                    <div
                      id="sc_title_864854065"
                      className="sc_title color_style_default sc_title_default mb-0"
                    >
                      <h6 className="sc_item_subtitle sc_title_subtitle text-left sc_item_title_style_default ">
                        Taste the Magic
                      </h6>
                      <h2 className="sc_item_title sc_title_title text-left sc_item_title_style_default sc_item_title_tag mb-0">
                        AMOUN'S RESTAURANT
                      </h2>
                    </div>

                    <div className="wpb_text_column wpb_content_element py-4 pe-5">
                      <div className="wpb_wrapper">
                        <p className="text-left fw-bold my-3">
                          FAST FOOD OUTLETS
                        </p>
                        <p className="text-left">
                          Amoun's Restaurant offers a delectable menu that
                          caters to all tastes. Our dishes are made with the
                          freshest ingredients to ensure that every bite is a
                          burst of flavor.
                        </p>
                        <p className="text-dark fw-bold mb-0">
                          <span className="sc_icon_type_ icon-icon_clock pe-2"></span>{" "}
                          Working Hours:
                        </p>{" "}
                        <p className="col-lg-3 d-flex justify-content-between text-main mb-0 fw-bold">
                          Beverages{" "}
                          <span className="text-secondary"> [ 10 : 16]</span>
                        </p>
                        <p className="col-lg-3 d-flex justify-content-between text-main mb-0 fw-bold">
                          {" "}
                          Food{" "}
                          <span className="text-secondary"> [ 12 : 16]</span>
                        </p>
                      </div>
                    </div>

                    <div className="sc_item_button sc_button_wrap text-left">
                      <a
                        href="amoun's-restaurant-menu.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="sc_button color_style_default sc_button_default sc_button_size_normal sc_button_icon_left sc_button_hover_slide_left"
                      >
                        <span className="sc_button_text">
                          <span className="sc_button_title">
                            Download Menu
                            <FontAwesomeIcon
                              className="ms-2"
                              icon="fa-solid fa-download"
                            />
                          </span>
                        </span>
                      </a>
                    </div>
                  </Fade>
                </div>

                <div
                  className="col-11 col-lg-6"
                  ref={tiltRef1}
                  onMouseMove={tiltHandler1.handleMove}
                  onMouseOut={tiltHandler1.handleMouseOut}
                  onMouseDown={tiltHandler1.handleMouseDown}
                  onMouseUp={tiltHandler1.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade direction="right" duration={1500} delay={100}>
                    <img
                      src={retaurantImg1}
                      alt="AMOUN'S RESTAURANT"
                      loading="lazy"
                    />
                  </Fade>
                </div>
              </div>
              {/*R2 */}
              <div className="col-11 col-lg-12 mx-auto d-flex flex-column flex-lg-row justify-content-between align-items-center py-lg-5   ">
                <div
                  className="col-11 col-lg-6"
                  ref={tiltRef2}
                  onMouseMove={tiltHandler2.handleMove}
                  onMouseOut={tiltHandler2.handleMouseOut}
                  onMouseDown={tiltHandler2.handleMouseDown}
                  onMouseUp={tiltHandler2.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade direction="left" duration={1500} delay={100}>
                    <img src={retaurantImg2} alt="PIZZERIA" loading="lazy" />
                  </Fade>
                </div>
                <div className="col-11 col-lg-6 py-4 ps-lg-5 pe-lg-2">
                  <Fade direction="right" duration={1000} delay={100}>
                    <div className="sc_title color_style_default sc_title_default ps-3">
                      <h6 className="sc_item_subtitle sc_title_subtitle text-left sc_item_title_style_default">
                        Unleash Your Inner Food Connoisseur
                      </h6>
                      <h2 className="sc_item_title sc_title_title text-left sc_item_title_style_default sc_item_title_tag">
                        PIZZERIA
                      </h2>
                    </div>

                    <div className="wpb_text_column wpb_content_element py-4 ps-3 pe-5">
                      <div className="wpb_wrapper">
                        <p className="text-left pt-2">
                          We offer a variety of traditional and creative pizzas
                          to accommodate
                          <br /> various preferences.
                        </p>
                        <p className="text-dark fw-bold mb-0">
                          Working Hours:
                        </p>{" "}
                        <p className="text-main mb-0 fw-bold">
                          <span className="sc_icon_type_ icon-icon_clock pe-2"></span>
                          Everyday from [ 10 am till 12pm]
                        </p>
                      </div>
                    </div>

                    {/*<div className="sc_item_button sc_button_wrap text-left ps-3">
                      <NavLink
                        to="#."
                        className="sc_button color_style_default sc_button_default sc_button_size_normal sc_button_icon_left sc_button_hover_slide_left"
                      >
                        <span className="sc_button_text">
                          <span className="sc_button_title">
                            Download Menu{" "}
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                          </span>
                        </span>
                      </NavLink>
                    </div> */}
                  </Fade>
                </div>
              </div>
            </article>
          </Container>
        </div>
      </section>
    </>
  );
};

export default FoodAndDrinks;
