import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home";
import ScrollToTop from "./ScrollToTop";
import WaterDropCursor from "./WaterDropCursor";
// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ContactUs from "./pages/contact-us/ContactUs";
import Gallery from "./pages/gallery/Gallery";
import Membership from "./pages/membership/Membership";
import Attractions from "./pages/attractions/Attractions.jsx";
import { Button } from "react-bootstrap";
import FacilitiesPage from "./pages/facilities/Facilities.jsx";
import WaterSlides from "./pages/water-slides/WaterSlides.jsx";
import FoodAndDrinks from "./pages/food-and-drinks/FoodAndDrinks.jsx";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions.jsx";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy.jsx";
import { Provider } from "react-redux";
import { store } from "./data/index.js";
import Beach from "./pages/beach/Beach.jsx";
import PageTracker from "./components/PageTracker.js";
import ErrorPage from "./pages/error-page/NotFound.jsx";
library.add(fas);

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <PageTracker />
          <WaterDropCursor />

          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="beach" element={<Beach />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="membership" element={<Membership />} />
              {/* <Route path="/attractions" element={<Attractions />} /> */}
              <Route path="facilities" element={<FacilitiesPage />} />
              <Route path="water-slides" element={<WaterSlides />} />
              <Route path="food-and-drinks" element={<FoodAndDrinks />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
