import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./css/contact-us.css";
import { Bounce, Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
const ContactUs = () => {
  const initialState = {
    customerName: "",
    customerMobile: "",
    customerEmail: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [response, setResponse] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = "https://api.cleoparksharm.com/api/contactUs/createOrder";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      if (!response.ok) throw new Error("Network response was not ok");
      // Handle success response
      setResponse("Form submitted successfully");
      setFormData(initialState);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setResponse("There was a problem with the fetch operation");
    }
  };
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <section>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1502376432008 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
          <div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div
                  id="sc_content_1271549084"
                  className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center"
                >
                  <div className="sc_content_container">
                    <div className="sc_layouts_item">
                      <div
                        id="sc_layouts_title_520295187"
                        className="sc_layouts_title with_content"
                      >
                        <div className="sc_layouts_title_content">
                          <div className="sc_layouts_title_title">
                            <Bounce headShake duration={2000}>
                              {" "}
                              <h1 className="sc_layouts_title_caption">
                                Contact Us
                              </h1>
                            </Bounce>
                            <ul className="breadcrumb">
                              <Fade direction="up" duration={2000}>
                                {" "}
                                <li>
                                  <Link to="/">Home</Link>
                                </li>
                                <li>Contact Us</li>
                              </Fade>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="first-section" id="contact-us">
        <Container>
          <Fade cascade direction="up" duration={1000} delay={0}>
            {" "}
            <h6 className="sc_item_subtitle sc_services_subtitle sc_align_center sc_item_title_style_default mb-3">
              We're Here to Help
            </h6>
            <h2 className="sc_item_title sc_services_title sc_align_center sc_item_title_style_default sc_item_title_tag mb-5 pb-5">
              Contact the CLEOPARK Team
            </h2>
          </Fade>
          <div className="col-10 mx-auto  d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between bg-light">
            <div className="col-12 col-lg-6  mx-auto mx-lg-0 p-5 contact-us-form">
              <div className="sc_title color_style_default sc_title_default mb-5  pt-5">
                <Fade cascade direction="up" duration={500}>
                  <h6 className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_title_style_default ">
                    Feel free to write us
                  </h6>
                </Fade>
              </div>

              <Form onSubmit={handleSubmit}>
                <Fade direction="left" duration={500}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="text-light fs-5">
                      Full Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      name="customerName"
                      value={formData.customerName}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="text-light fs-5">
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      required
                      name="customerMobile"
                      value={formData.customerMobile}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formBasicEmail">
                    <Form.Label className="text-light fs-5">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      required
                      name="customerEmail"
                      value={formData.customerEmail}
                      onChange={handleInputChange}
                    />
                    <Form.Text className="fs-5 text-secondary text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-light fs-5">Message</Form.Label>
                    <textarea
                      rows="6"
                      className="wpcf7-form-control wpcf7-textarea col-12"
                      aria-invalid="false"
                      placeholder="Your Message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    ></textarea>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      required
                      className=" ps-0 "
                      type="checkbox"
                      label={
                        <span className=" ps-0 text-light">
                          By using this form you agree with the storage and
                          handling of your data by this website.
                        </span>
                      }
                    />
                  </Form.Group>

                  <div className="sc_item_button sc_button_wrap sc_align_right text-center">
                    <Button
                      className="sc_button color_style_default sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left sc_button_icon_left sc_button_size_small submit-btn"
                      variant="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Fade>
              </Form>
              <p className="mx-auto text-center text-main mt-5 mb-0">
                <u>{response}</u>
              </p>
            </div>
            <div className="col-12 col-lg-5 p-5 p-lg-0 pe-0 pe-lg-5 ">
              <div className="vc_column-inner mb-5 mb-lg-0">
                <Fade direction="right" duration={1500}>
                  <div className="wpb_wrapper ">
                    <div className="sc_title color_style_default sc_title_default">
                      <h6 className="sc_item_subtitle sc_title_subtitle sc_align_left sc_item_title_style_default">
                        We are waiting for you
                      </h6>
                      <h2 className="sc_item_title sc_title_title sc_align_left sc_item_title_style_default">
                        Connect With Us
                      </h2>
                      <div className="sc_item_descr sc_title_descr sc_align_left my-5">
                        <p className="mb-0">
                          Our team is always available to assist you with any
                          inquiries you may have. They are more than happy to
                          help you out. contact us page.
                        </p>
                      </div>
                    </div>
                    <div className="sc_icons sc_icons_default sc_icons_size_small sc_align_left mt-0">
                      <div className="sc_icons_item sc_icons_item_linked">
                        <div className="sc_icons_icon sc_icon_type_ icon-icon_phone">
                          <span className="sc_icon_type_ icon-icon_phone"></span>
                        </div>
                        <h4 className="sc_icons_item_title">
                          <span>Call Us Today +20693603040</span>
                        </h4>
                        <a
                          href="tel:+201212876669"
                          className="sc_icons_item_link"
                        ></a>
                      </div>
                      <div className="sc_icons_item sc_icons_item_linked">
                        <div className="sc_icons_icon sc_icon_type_ icon-icon_envelope">
                          <span className="sc_icon_type_ icon-icon_envelope"></span>
                        </div>
                        <h4 className="sc_icons_item_title">
                          <span>
                            <span
                              style={{ cursor: "pointer" }}
                              className="__cf_email__"
                            >
                              fo.sharmdreams@jazhotels.com
                            </span>
                          </span>
                        </h4>
                        <a
                          href="mailto:fo.sharmdreams@jazhotels.com"
                          className="sc_icons_item_link"
                        ></a>
                      </div>
                      <div className="sc_icons_item">
                        <div className="sc_icons_icon sc_icon_type_ icon-icon_clock">
                          <span className="sc_icon_type_ icon-icon_clock"></span>
                        </div>
                        <h4 className="sc_icons_item_title">
                          <span>Opening Hours 10:00 - 16:00</span>
                        </h4>
                      </div>
                      <div className="sc_icons_item sc_icons_item_linked">
                        <div className="sc_icons_icon sc_icon_type_ icon-icon_pin">
                          <span className="sc_icon_type_ icon-icon_pin"></span>
                        </div>
                        <h4 className="sc_icons_item_title">
                          <span>
                            Sharm Dreams Resort – Naama Bay –<br /> Sharm
                            el-Sheikh, Egypt
                          </span>
                        </h4>
                        <Link
                          to="https://maps.app.goo.gl/H8oRCavnUwKT8yEs9"
                          className="sc_icons_item_link"
                          target="_Blank"
                        ></Link>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <div className="mt-5 pt-5 ">
          <div className="col-12 col-lg-12 ps-0">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="sc_googlemap_wrap">
                  <Zoom duration={1500}>
                    <div
                      className="sc_googlemap sc_googlemap_default"
                      style={{
                        width: "100%",
                        height: "645px",
                      }}
                      data-zoom="12"
                      data-style="grey"
                      data-cluster-icon
                    >
                      <iframe
                        title="location"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14102.066543678462!2d34.3229803!3d27.9168029!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1453382f095ecc55%3A0x8af0436c2c374815!2sCleo%20Park!5e0!3m2!1sen!2seg!4v1703596661912!5m2!1sen!2seg"
                      ></iframe>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
