import { createSlice } from "@reduxjs/toolkit";
// import { getTours } from "./thunk";
// import { getProducts } from "./thunk";
// import { insertNotes } from "./thunk";
// import { filterTours } from "./thunk";
// import { isCompleted } from "./thunk";
// import axios from "axios";

const initialState = {
  locations: [
    {
      id: 1,
      icon: "fa-chess-queen",
      left: "60%",
      top: "30%",
      text: "QUEENS TROPHY",
    },

    {
      id: 5,
      icon: "fa-location-dot",
      left: "57%",
      top: "21%",
      text: "SOLAR BOAT",
    },

    {
      id: 7,
      icon: "fa-shower",
      left: "54%",
      top: "51%",
      text: "Showers & Toilets",
    },
    {
      id: 8,
      icon: "fa-location-dot",
      left: "39%",
      top: "46%",
      text: "NILE SPRING CRUISE",
    },
    {
      id: 9,
      icon: "fa-location-dot",
      left: "35%",
      top: "42%",
      text: "NILE Discovery CRUISE",
    },

    {
      id: 10,
      icon: "fa-location-dot",
      left: "33%",
      top: "54%",
      text: "Baby Pharaoh's Pool",
    },

    {
      id: 11,
      icon: "fa-location-dot",
      left: "30%",
      top: "46%",
      text: "Young Pharaoh's Oasis",
    },
    {
      id: 12,
      icon: "fa-spa",
      left: "29%",
      top: "39%",
      text: "Royal Harbor & Spa",
    },

    {
      id: 13,
      icon: "fa-people-roof",
      left: "31%",
      top: "64%",
      text: "Oasis Family Slide",
    },
    {
      id: 14,
      icon: "fa-shop-lock",
      left: "72%",
      top: "46%",
      text: "Male & Female Lockers",
    },

    {
      id: 16,
      icon: "fa-utensils",
      left: "49.5%",
      top: "46%",
      text: "Varieties Fast Food Outlets",
    },

    {
      id: 17,
      icon: "fa-water-ladder",
      left: "52%",
      top: "48%",
      text: "Cleopatra Path",
    },
    {
      id: 18,
      icon: "fa-shop",
      left: "71%",
      top: "42%",
      text: "Gift Shop",
    },
    {
      id: 19,
      icon: "fa-house-chimney-medical",
      left: "64.2%",
      top: "63%",
      text: "Clinic",
    },
    {
      id: 20,
      icon: "fa-square-parking",
      left: "75%",
      top: "35%",
      text: "Free Parking Area",
    },
    {
      id: 21,
      icon: "fa-staff-snake",
      left: "55%",
      top: "34%",
      text: "SNAKE TERROR",
    },

    {
      id: 22,
      icon: "fa-location-dot",
      left: "46%",
      top: "35%",
      text: "SCORPIO ATTACK",
    },
    {
      id: 23,
      icon: "fa-location-dot",
      left: "49%",
      top: "23%",
      text: "LAST TEMPLE",
    },
    {
      id: 24,
      icon: "fa-location-dot",
      left: "38%",
      top: "29%",
      text: "The Lost Pyramid",
    },
    {
      id: 25,
      icon: "fa-utensils",
      left: "59.5%",
      top: "53%",
      text: "Amoun's Restaurant",
    },
  ],
};
export const mapSlice = createSlice({
  name: "map",
  initialState,

  reducers: {},
});

export const { getLocations } = mapSlice.actions;
export default mapSlice.reducer;
