import React, { useRef } from "react";
import { Bounce, Fade } from "react-awesome-reveal";
import { Button, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import beachImg from "./beach.jpg";
import { Helmet } from "react-helmet";

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>

      <section style={{ marginTop: "12vh" }} id="welcome-section ">
        <div className="  py-5">
          <Container>
            <article className="py-5 my-5">
              <div className="container text-center mt-5">
                <h1 className="text-danger">
                  Oops! <br />
                  Page Not Found
                </h1>
                <Button
                  className="fs-2 bg-clepark px-5"
                  onClick={() => (window.location = "/")}
                >
                  Back TO Home
                </Button>
              </div>
            </article>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
