import React from "react";
import { Link } from "react-router-dom";
import Fancybox from "../../components/fancybox-library/fancybox";
import styles from "./css/gallery.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import galleryImg1 from "../../components/images/gallery1.webp";
import galleryImg2 from "../../components/images/gallery2.webp";
import galleryImg3 from "../../components/images/gallery3.webp";
import galleryImg4 from "../../components/images/gallery4.webp";
import { Fade, Roll } from "react-awesome-reveal";
const Gallery = () => {
  return (
    <div className="vc_row wpb_row vc_row-fluid vc_row-no-padding">
      <div className=" mx-auto my-5 pt-5">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="col-10 mx-auto   sc_title color_style_default sc_title_default mb-5 pb-5">
              <Fade cascade direction="up" duration={1000} delay={0}>
                {" "}
                <h6 className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_title_style_default text-center mb-3">
                  Enjoyment and activities that never end
                </h6>
                <h2 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag text-center">
                  <Link to="gallery" className={styles.titleLink}>
                    Immersive Water Park Experiences
                  </Link>
                </h2>
              </Fade>
            </div>

            <article
              className="myportfolio-container esg-grid-wrap-container minimal-light esg-entry-skin-playa source_type_post"
              data-alias="Home Gallery"
            >
              <div
                className={styles.gallery}
                style={{ margin: "auto", textAlign: "center" }}
              >
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <a
                    data-fancybox="gallery"
                    href={galleryImg1}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition `}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg1}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Roll duration={1500}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg1}
                        width="380"
                        height="400"
                      />
                    </Roll>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg2}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg2}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Roll duration={1500}>
                      <img
                        alt="gallery"
                        src={galleryImg2}
                        width="380"
                        height="400"
                      />
                    </Roll>
                  </a>
                  <a
                    data-fancybox="gallery"
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition`}
                    href={galleryImg3}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg3}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Roll duration={1500}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg3}
                        width="380"
                        height="400"
                      />
                    </Roll>
                  </a>
                  <a
                    data-fancybox="gallery"
                    href={galleryImg4}
                    className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition`}
                  >
                    <a
                      className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii ${styles.egplayaelement0a}`}
                      href={galleryImg4}
                    >
                      <i className="eg-icon-plus"></i>
                    </a>
                    <Roll duration={1500}>
                      {" "}
                      <img
                        alt="gallery"
                        src={galleryImg4}
                        width="380"
                        height="400"
                      />
                    </Roll>
                  </a>
                </Fancybox>
              </div>
            </article>
            <div className="row mx-auto text-center">
              <Link
                className="sc_button color_style_default sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left sc_button_icon_left sc_button_size_small w-auto mx-auto mt-5"
                to="gallery"
              >
                <span className="sc_button_title">
                  view all{" "}
                  <FontAwesomeIcon
                    icon="fa-solid fa-arrow-up-right-from-square"
                    className={`ms-2 text-light  fs-4 p-1  `}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
