import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Here, you'd trigger the GTM page view event
    console.log(`Page view: ${location.pathname}`);

    // Assuming you have the GTM dataLayer initialized elsewhere (e.g., in your index.html)
    window.dataLayer.push({
      event: "pageview",
      page: location.pathname,
      // Add any additional parameters you wish to track
    });
  }, [location]);

  return null; // This component does not render anything
};

export default PageTracker;
